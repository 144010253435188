
/* CSS */
.button-28 {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #fff;
  background-color: #1A1A1A;
  border: none;
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}

.homecategory {
    text-align: center;
    font-family: Candara,Calibri,Segoe,Segoe UI,Optima,Arial,sans-serif;
    padding-bottom: 50px;
    background-color: white;
}

.category-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 200px; /* Optional: adds spacing between items */
  padding-top: 70px;
}

.category-item {
  margin: 5px;
  width: 180px;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.category-item:hover {
  border: 1px solid #777;
}

.category-item img {
  width: 100%;
  height: auto;
}

.desc {
  padding: 15px;
  text-align: center;
}
