body {
    background-image: linear-gradient(135deg, #8080D7 0%, #010922 100%);
    background-repeat: no-repeat;
    height: 89.4vh
}


.register-container {
    /* Tambahkan style ini untuk menengahkan konten */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Tambahkan untuk menengahkan vertikal */
    margin: auto;
    margin-top: 100px;
    width: 600px;
    background: #fff;
    padding-bottom: 30px;
    border-radius: 20px;
    text-align: center; /* Menengahkan horizontal */
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 20px;
}

.text {
    color: #2a0458;
    font-size: 48px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 7px;
}

.underline {
    width: 61px;
    height: 6px;
    background: #2a0458;
    border-radius: 9px;

}

.inputs {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: lightgray;
    border-radius: 6px;
    padding-left: 20px;
    font-size: 20px;
}



.input input {
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
    padding-left: 10px;
}

.submit-container {
    display: flex;
    gap: 30px;
    margin: 20px auto;
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: white;
    background: #2a0458;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}

.register-back-landing {
    padding-right: 520px;
}

.register-back-landing button {
    color: black;
    background-color: white;
    font-size: 20px;
    border: none;
  }
  
  .register-back-landing button:hover {
    color: #7F7F7F;
  }

.signup-text {
    color: #2a0458; /* Ubah warna teks sesuai kebutuhan */
    font-size: 18px; /* Sesuaikan ukuran font */
    margin-top: 0px; /* Sesuaikan margin sesuai kebutuhan */
}

.signup-text a {
    color: #8080D7; /* Ubah warna tautan sesuai kebutuhan */
    text-decoration: none; /* Hilangkan garis bawah default */
    font-weight: bold;
}

.signup-text a:hover {
    text-decoration: underline; /* Tambahkan garis bawah saat tautan dihover */
}
