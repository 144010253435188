body {
    background-image: linear-gradient(135deg, #8080D7 0%, #010922 100%);
    background-repeat: no-repeat;

}

.login-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 150px;
    width: 600px;
    background: #fff;
    padding-bottom: 30px;
    border-radius: 20px;
    
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text {
    color: #2a0458;
    font-size: 48px;
    font-weight: 700;
}

.underline {
    width: 61px;
    height: 6px;
    background: #2a0458;
    border-radius: 9px;
}

.inputs {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: lightgray;
    border-radius: 6px;
    padding-left: 20px;
    font-size: 20px;
}

.input input {
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
    padding-left: 10px;
}

.submit-container {
    display: flex;
    gap: 30px;
    margin: 60px auto;
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: white;
    background: #2a0458;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}

.signup-text {
    color: #2a0458;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
}

.signup-text a {
    color: #8080D7;
    text-decoration: none;
    font-weight: bold;
}

.signup-text a:hover {
    text-decoration: underline;
}

.login-back-landing {
    padding-right: 520px;
}

.login-back-landing button {
    color: black;
    background-color: white;
    font-size: 20px;
    border: none;
  }
  
  .login-back-landing button:hover {
    color: #7F7F7F;
  }
