

.about {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: linear-gradient(135deg, #8080D7 0%, #010922 100%);
    background-repeat: no-repeat;
    box-sizing: border-box;
    font-family: Candara,Calibri,Segoe,Segoe UI,Optima,Arial,sans-serif;
}

.team {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.team-content {
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    align-items: center;
    gap: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 60px;
}

.team-content img {
    width: 100%;
    height: auto;
    border-radius: 20%;
    margin-bottom: 15px;
}

.aboutcenter {
    color: #fff;
    font-size: 4rem;
    text-align: center;
}

.box {
    padding: 16px;
    border-radius: 15px;
    transition: all .38s ease;
}

.box h3 {
    font-size: 23px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 8px;

}

.box h5 {
    font-size: 15px;
    font-weight: 600;
    color: #b7b4bb;
    margin-bottom: 15px;
    letter-spacing: 2px;
}

.box:hover {
    transform: translateY(-10px);
    cursor: pointer;
}



